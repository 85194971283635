<template>
  <div>
    <table-page :config="config" />
  </div>
</template>

<script>
import TablePage from "@/components/TablePage";
import { grade, country, curriculumSystems, month, teachingFormat } from '@/utils/public';

export default {
  name: "serviceItems",
  components: {
    TablePage
  },
  data() {
    return {
      config: {
        /* 表格是否可多选 */
        dataUrl: '/clientUser/internationalSchool/enrollmentProgram/list?schoolId=' + this.$route.query.id,
        belongTo: '当前学校：' + this.$route.query.other,
        filter: {
          // initUrl: '/agency/institutionsEmployee/list/get',
          controls: []
        },
        columns: [
          {
            key: 'name',
            title: '项目名',
            width: '100px',
          },
          {
            key: 'gradeLevels',
            title: '开设年级',
            width: '100px',
          },
          {
            key: 'studyAbroadDirection',
            title: '留学方向',
            width: '100px',
          },
          {
            key: 'curriculumSystem',
            title: '课程体系',
            width: '100px',
          },
          {
            key: 'sort',
            title: '排序',
            width: '100px',
          },
          {
            key: 'locked',
            title: '显示状态',
            type: 'textCustom',
            width: '100px',
            config: {
              text: (val) => {
                return val == 0 ? '启用' : '停用'
              }
            }
          },
          {
            key: 'name',
            title: '操作',
            type: 'actions',
            fixed: 'right',
            width: document.documentElement.clientWidth <= 768 ? '80px' : '100px'
          }
        ],

        /* 表格排序 */
        sorter: {
          /* 可排序列 */
          sortableColumns: [''],
          /* 默认排序 */
          defaultSort: ''
        },
        checkbox: true,
        actions: [
          {
            key: 'edit',
            text: '新建招生项目',
            type: 'form',
            permission: '/clientUser/internationalSchool/enrollmentProgram/add',
            config: {
              color: 'primary',
              title: '新建招生项目',
              submitUrl: "/clientUser/internationalSchool/enrollmentProgram/add",
              submitText: '保存',
              controls: [
                {
                  key: 'logo',
                  label: '封面图片',
                  required: true,
                  tips: "上传比例为 xxx:xxx，大小不超过3mb的图片",
                  type: 'file',
                  config: {
                    uploadUrl: '/api/clientUser/institutionalShop/addUpload',
                    format: "image",
                    accept: ["jpeg", "png", 'jpg'],
                    maxSize: 1024*3000
                  }
                },
                {},
                {
                  key: 'internationalSchoolId',
                  label: '学校名',
                  type: 'select',
                  required: true,
                  disabled: true,
                  defaultValue: this.$route.query.id,
                  config: {
                    options: [
                      {
                        value: this.$route.query.id,
                        text: this.$route.query.other,
                      }
                    ]
                  }
                },
                {
                  key: 'name',
                  label: '项目名',
                  type: 'text',
                  required: true,
                },
                {
                  key: 'gradeLevels',
                  label: '开设年级',
                  type: 'mSelect',
                  dealData: true,
                  required: true,
                  config: {
                    options: grade
                  }
                },
                {
                  key: 'studyAbroadDirection',
                  label: '留学方向',
                  type: 'mSelect',
                  dealData: true,
                  required: true,
                  config: {
                    options: country
                  }
                },
                {
                  key: 'curriculumSystem',
                  label: '课程体系',
                  type: 'select',
                  required: true,
                  config: {
                    options: curriculumSystems
                  }
                },
                {
                  key: 'enrollmentTime',
                  label: '招生时间',
                  type: 'select',
                  required: true,
                  config: {
                    options: [
                      {
                        id: '循环招生',
                        name: '循环招生',
                        text: '循环招生',
                        value: '循环招生',
                      }
                    ]
                  }
                },
                {
                  key: 'openTime',
                  label: '开学时间',
                  type: 'mSelect',
                  dealData: true,
                  config: {
                    options: month
                  }
                },
                {
                  key: 'classSize',
                  label: '班级大小',
                  type: 'numeric',
                  config: {
                    format: 'unit',
                    text: '人左右'
                  }
                },
                {
                  key: 'teachingFormat',
                  label: '授课形势',
                  type: 'select',
                  required: true,
                  config: {
                    options: teachingFormat
                  }
                },
                {
                  start: 'minTuitionFee',
                  end: 'maxTuitionFee',
                  label: '年学费参考 (人民币)',
                  unit: '元',
                  type: 'numberRange',
                  config: {
                    options: 'stayType'
                  }
                },
                {
                  key: 'sort',
                  label: '排序',
                  type: 'numeric',
                  config: {
                    format: 'sort'
                  }
                },
                {
                  key: 'introduction',
                  label: '招生简章',
                  type: 'editor',
                  required: true,
                  config: {
                    uploadUrl: '/api/order/institutionsOrder/addUpload'
                  }
                },
              ]
            }
          },
          {
            key: 'edit',
            text: '删除',
            icon: 'plus',
            type: 'dialog',
            isSelected: true,  // 是否必勾选
            determine: true,  // 是否需要判定条
            permission: '/clientUser/internationalSchool/enrollmentProgram/delete',
            modalObj: (rowData, tableData) => {
              return `您共选择 ${rowData.length} 条招生项目，是否全部删除? `
            },
            config: {
              title: '删除成功',
              submitUrl: '/clientUser/internationalSchool/enrollmentProgram/delete',
              submitText: '确认',
              controls: [
                {
                  key: 'internationalSchoolId',
                  label: '学校名',
                  type: 'select',
                  disabled: true,
                  defaultValue: this.$route.query.id,
                  config: {
                    options: [
                      {
                        value: this.$route.query.id,
                        text: this.$route.query.other,
                      }
                    ]
                  }
                },
              ]
            }
          },
        ],
        /* 表格行操作 */
        rowActions: [
          {
            key: 'edit',
            text: '编辑',
            type: 'form',
            permission: '/clientUser/internationalSchool/enrollmentProgram/edit',
            config: {
              color: 'primary',
              title: '编辑',
              initUrl: "/clientUser/internationalSchool/enrollmentProgram/edit/get",
              submitUrl: "/clientUser/internationalSchool/enrollmentProgram/edit",
              submitText: '保存',
              controls: [
                {
                  key: 'logo',
                  label: '封面图片',
                  required: true,
                  tips: "上传比例为 xxx:xxx，大小不超过3mb的图片",
                  type: 'file',
                  config: {
                    uploadUrl: '/api/clientUser/institutionalShop/addUpload',
                    format: "image",
                    accept: ["jpeg", "png", 'jpg'],
                    maxSize: 1024*3000
                  }
                },
                {},
                {
                  key: 'internationalSchoolId',
                  label: '学校名',
                  type: 'select',
                  required: true,
                  disabled: true,
                  defaultValue: this.$route.query.id,
                  config: {
                    options: [
                      {
                        value: this.$route.query.id,
                        text: this.$route.query.other,
                      }
                    ]
                  }
                },
                {
                  key: 'name',
                  label: '项目名',
                  type: 'text',
                  required: true,
                },
                {
                  key: 'gradeLevels',
                  label: '开设年级',
                  type: 'mSelect',
                  dealData: true,
                  required: true,
                  config: {
                    options: grade
                  }
                },
                {
                  key: 'studyAbroadDirection',
                  label: '留学方向',
                  type: 'mSelect',
                  dealData: true,
                  required: true,
                  config: {
                    options: country
                  }
                },
                {
                  key: 'curriculumSystem',
                  label: '课程体系',
                  type: 'select',
                  required: true,
                  config: {
                    options: curriculumSystems
                  }
                },
                {
                  key: 'enrollmentTime',
                  label: '招生时间',
                  type: 'select',
                  required: true,
                  config: {
                    options: [
                      {
                        id: '循环招生',
                        name: '循环招生',
                        text: '循环招生',
                        value: '循环招生',
                      }
                    ]
                  }
                },
                {
                  key: 'openTime',
                  label: '开学时间',
                  type: 'mSelect',
                  dealData: true,
                  config: {
                    options: month
                  }
                },
                {
                  key: 'classSize',
                  label: '班级大小',
                  type: 'numeric',
                  config: {
                    format: 'unit',
                    text: '人左右'
                  }
                },
                {
                  key: 'teachingFormat',
                  label: '授课形势',
                  type: 'select',
                  required: true,
                  config: {
                    options: teachingFormat
                  }
                },
                {
                  start: 'minTuitionFee',
                  end: 'maxTuitionFee',
                  label: '年学费参考 (人民币)',
                  unit: '元',
                  type: 'numberRange',
                  config: {
                    options: 'stayType'
                  }
                },
                {
                  key: 'sort',
                  label: '排序',
                  type: 'numeric',
                  config: {
                    format: 'sort'
                  }
                },
                {
                  key: 'introduction',
                  label: '招生简章',
                  type: 'editor',
                  required: true,
                  config: {
                    uploadUrl: '/api/order/institutionsOrder/addUpload'
                  }
                },
                {
                  key: 'version',
                  label: '',
                },
                {}
              ]
            }
          },
          {
            key: 'lock',
            text: '停用',
            type: 'confirm',
            permission: '/clientUser/internationalSchool/enrollmentProgram/lock',
            disabled({ selectedRows }) {
              return selectedRows[0].locked === false;
            },
            config: {
              color: 'danger',
              submitColor: 'danger',
              submitUrl: '/clientUser/internationalSchool/enrollmentProgram/lock',
              otherKey: 'internationalSchoolId',
              otherValue: this.$route.query.id
            }
          },
          {
            key: 'unlock',
            text: '启用',
            type: 'confirm',
            permission: '/clientUser/internationalSchool/enrollmentProgram/unlock',
            disabled({ selectedRows }) {
              return selectedRows[0].locked === true;
            },
            config: {
              submitUrl: '/clientUser/internationalSchool/enrollmentProgram/unlock',
              otherKey: 'internationalSchoolId',
              otherValue: this.$route.query.id
            }
          },
        ]
      }
    }
  },
  async mounted() {
  },
  onUnmounted() {

  },
  computed: {

  },

  methods: {

  }
}
</script>

<style scoped lang="scss"></style>